import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import PrivateSlideshow from '../components/PrivateSlideshow';

const PrivateClient = ({ location }) => (
  <main>
    <Layout>
      <PrivateSlideshow location={location}></PrivateSlideshow>
    </Layout>
  </main>
);

PrivateClient.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PrivateClient;

import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useKey } from 'rooks';

import isBrowser from '../utils/isBrowser';
import { clientSlugToUrl } from '../utils/urls';

import Client from './Client';
import * as layoutStyles from './Layout.module.scss';
import * as styles from './Slideshow.module.scss';

const PrivateSlideshow = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
      {
        allPrismicPrivateClients {
          nodes {
            data {
              slug {
                text
              }
            }
          }
          edges {
            node {
              data {
                body {
                  ... on PrismicPrivateClientsBodyMedia {
                    id
                    slice_type
                    primary {
                      slug {
                        text
                      }
                      desktop_media {
                        raw
                      }
                      mobile_media {
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
      render={data => {
        const locationParts = location.pathname.split('/');
        const slug = locationParts[1];
        const index = data.allPrismicPrivateClients.nodes.findIndex(node => node.data.slug.text === slug) || 0;
        const clients = data.allPrismicPrivateClients.edges[index].node.data.body;
        const slides = [
          ...clients.map(client => ({
            key: clientSlugToUrl(client.primary.slug.text, slug),
            component: {
              type: Client,
              props: {
                client,
                isPrivate: true,
              },
            },
          })),
        ];

        const nextSlideIndex = index => {
          return index < slides.length - 1 ? index + 1 : 0;
        };

        const previousSlideIndex = index => {
          return index > 0 ? index - 1 : slides.length - 1;
        };

        const updateHistory = path => {
          if (isBrowser) {
            window.history.replaceState({}, '', path); // eslint-disable-line no-undef
          }
        };

        const changeSlide = (event, direction) => {
          const currentSlideIndex = slides.findIndex(s => s.key === currentSlide.key);
          const newSlideIndex = (direction > 0 ? nextSlideIndex : previousSlideIndex)(currentSlideIndex);

          setCurrentSlide(slides[newSlideIndex]);
          setNextSlide(slides[nextSlideIndex(newSlideIndex)]);
          setPreviousSlide(slides[previousSlideIndex(newSlideIndex)]);
          updateHistory(slides[newSlideIndex].key);

          if (event) {
            event.preventDefault();
          }
        };

        const pathname = location.pathname === '/' ? '/' : location.pathname.replace(/\/$/, '');
        let initialSlideIndex = slides.findIndex(slide => slide.key === pathname);

        if (initialSlideIndex === -1) {
          initialSlideIndex = 0;
        }

        const [currentSlide, setCurrentSlide] = useState(slides[initialSlideIndex]);
        const [nextSlide, setNextSlide] = useState(slides[nextSlideIndex(initialSlideIndex)]);
        const [previousSlide, setPreviousSlide] = useState(slides[previousSlideIndex(initialSlideIndex)]);

        const swipeHandlers = useSwipeable({
          onSwipedLeft: () => changeSlide(null, -1),
          onSwipedRight: () => changeSlide(null, 1),
          onSwipedUp: () => changeSlide(null, -1),
          onSwipedDown: () => changeSlide(null, 1),
        });

        const [isHoldingLeft, setIsHoldingLeft] = useState(false);
        useKey(['ArrowLeft'], e => {
          if (e.type === 'keydown') {
            if (!isHoldingLeft) {
              changeSlide(null, -1);
            }

            setIsHoldingLeft(true);
          }

          if (e.type === 'keyup') {
            setIsHoldingLeft(false);
          }
        }, { eventTypes: ['keyup', 'keydown'] } );

        const [isHoldingRight, setIsHoldingRight] = useState(false);
        useKey(['ArrowRight'], e => {
          if (e.type === 'keydown') {
            if (!isHoldingRight) {
              changeSlide(null, 1);
            }

            setIsHoldingRight(true);
          }

          if (e.type === 'keyup') {
            setIsHoldingRight(false);
          }
        }, { eventTypes: ['keyup', 'keydown'] } );

        return (
          <div {...swipeHandlers}>
            <div className={styles.slides}>
              {slides.map((slide) => {
                const isActive = slide.key === currentSlide.key;
                const isPrevious = slide.key === previousSlide.key;
                const isNext = slide.key === nextSlide.key;
                const Component = slide.component.type;
                const props = { isActive, isPrevious, isNext, ...slide.component.props };

                return (
                  <div
                    key={slide.key}
                    className={`${styles.slide} ${layoutStyles.wrapper}`}
                  >
                    { isActive || isPrevious || isNext ?
                      <Component {...props} />
                      : <> </> }
                  </div>
                );
              })}
            </div>
            <a className={`${styles.button} ${styles.next}`} href={nextSlide.key} onClick={e => changeSlide(e, 1)}>Next slide</a>
            <a className={`${styles.button} ${styles.previous}`} href={previousSlide.key} onClick={e => changeSlide(e, -1)}>Previous slide</a>
          </div>
        );
      }}
    ></StaticQuery>
  );
};

PrivateSlideshow.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PrivateSlideshow;
